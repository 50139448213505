import typo from "../img/euforia/typo.png"
import flyer from "../img/euforia/plakat-banner.png"
import boehm from "../img/euforia/boehm.jpg"
import horn from "../img/euforia/horn.jpg"
import duddeck from "../img/euforia/duddeck.jpg"
import frey from "../img/euforia/frey.jpg"
import krueger from "../img/euforia/krueger.jpeg"
import dueker from "../img/euforia/dueker.jpg"

function Euforia() {
    return (
        <div className={"container mx-auto max-w-screen-lg px-8"}>
            <div className={"md:pt-[8rem] pt-[4rem] pb-8"}>
                <img className={"object-contain max-h-[5rem]"} src={typo} alt={"EUFORIA"} />
            </div>
            <p className={"text-white text-md font-serif pb-4"}>EUFORIA ist eine einmalige Konzert-Performance, in deren
                Mittelpunkt geistliche Madrigale von Johann Hermann Schein stehen. Der wohl berühmteste Thomaskantor vor
                Bach schuf mitten im 30-jährigen Krieg Lieder für Chor, mit denen er den Menschen seiner Zeit Freude und
                Ermutigung schenken wollte. Diese kompositorische Glanzleistung ist weitestgehend vergessen. Die
                sensible poetische Kunst von JOMI und ein Sextett aus professionellen Musikerinnen und Musikern (mit
                Gesang, Bandoneon, Gambe, Barockgitarre, Chitarrone und E-Bass) lassen auf ungewöhnliche Weise diese
                Klänge neu auferstehen.</p>
            <h1 className={"font-thin break-words font-sans text-[#c51114] text-5xl antialiased mt-[5rem]"}>Veranstaltungen</h1>
            <p className={"text-white text-md font-serif pt-4"}>In 2024 kommen JOMI und das Ensemble refresh mit EUFORIA
                zu fünf Veranstaltungen zusammen:</p>
            <div className={"grid md:grid-cols-3 grid-cols-1 gap-8 justify-items-center justify-center md:pt-12 pt-8 pb-8"}>
                <div
                    className={"md:rounded-xl lg:border md:border-gray-500 md:p-4 md:pt-7 md:w-[17rem] md:aspect-square grid justify-center content-start"}>
                    <h2 className={"text-center text-white text-3xl font-sans font-thin antialised"}>Tholey</h2>
                    <p className={"pt-2 text-center text-white text-md font-serif"}>Benediktiner Abtei St. Mauritius</p>
                    <h3 className={"md:pt-8 pt-4 text-center text-white text-2xl font-sans font-thin antialiased"}>Freitag,
                        25. Oktober</h3>
                    <p className={"pt-2 text-center text-white text-md font-serif"}>19.30 Uhr</p>
                </div>
                <div
                    className={"md:rounded-xl lg:border md:border-gray-500 md:p-4 md:pt-7 md:w-[17rem] md:aspect-square md:mt-0 mt-4 grid justify-center content-start"}>
                    <h2 className={"text-center text-white text-3xl font-sans font-thin antialised"}>Bliesmengen-Bolchen</h2>
                    <p className={"pt-2 text-center text-white text-md font-serif"}>Kirche St. Paulus</p>
                    <h3 className={"md:pt-8 pt-4 text-center text-white text-2xl font-sans font-thin antialiased"}>Samstag,
                        26. Oktober</h3>
                    <p className={"pt-2 text-center text-white text-md font-serif"}>19.30 Uhr</p>
                </div>
                <div
                    className={"md:rounded-xl lg:border md:border-gray-500 md:p-4 md:pt-7 md:w-[17rem] md:aspect-square md:mt-0 mt-4 grid justify-center content-start"}>
                    <h2 className={"text-center text-white text-3xl font-sans font-thin antialised"}>Rehlingen-Siersburg</h2>
                    <p className={"pt-2 text-center text-white text-md font-serif"}>Kirche St. Martin</p>
                    <h3 className={"md:pt-8 pt-4 text-center text-white text-2xl font-sans font-thin antialiased"}>Sonntag,
                        27. Oktober</h3>
                    <p className={"pt-2 text-center text-white text-md font-serif"}>17.00 Uhr</p>
                </div>
                <div
                    className={"md:rounded-xl lg:border md:border-gray-500 md:p-4 md:pt-7 md:w-[17rem] md:aspect-square md:mt-0 mt-4 grid justify-center content-start"}>
                    <h2 className={"text-center text-gray-500 text-3xl font-sans font-thin antialised"}>Quickborn</h2>
                    <p className={"pt-2 text-center text-gray-500 text-md font-serif"}>St. Marien, Kurzer Kamp</p>
                    <h3 className={"md:pt-8 pt-4 text-center text-gray-500 text-2xl font-sans font-thin antialiased"}>Freitag,
                        14. Juni</h3>
                    <p className={"pt-2 text-center text-gray-500 text-md font-serif"}>19.30 Uhr</p>
                </div>
                <div
                    className={"md:rounded-xl lg:border md:border-gray-500 md:p-4 md:pt-7 md:w-[17rem] md:aspect-square md:mt-0 mt-4 grid justify-center content-start"}>
                    <h2 className={"text-center text-gray-500 text-3xl font-sans font-thin antialised"}>Kassel</h2>
                    <p className={"pt-2 text-center text-gray-500 text-md font-serif"}>Auferstehungskirche</p>
                    <h3 className={"md:pt-8 pt-4 text-center text-gray-500 text-2xl font-sans font-thin antialiased"}>Samstag,
                        15. Juni</h3>
                    <p className={"pt-2 text-center text-gray-500 text-md font-serif"}>19.30 Uhr</p>
                </div>
            </div>
            <p className={"text-white text-md font-serif pt-4"}>Tickets zu den Veranstaltungen können Sie unter <a href={"mailto:buero@pantomime-jomi.de"} className={"text-gray-500 text-md font-serif"}>buero@pantomime-jomi.de</a> zu je 25 EUR vorbestellen. Diese werden an der Abendkasse für Sie hinterlegt.</p>
            <div id={"ensemble"} className={"mt-[3rem]"}>
                <h1 className={"font-thin break-words font-sans text-[#c51114] text-5xl antialiased"}>Das Ensemble
                    refresh</h1>
                <div className={"flex md:flex-row flex-col md:mt-8 mt-0"}>
                    <img className={"object-contain max-h-[25rem] flex-none md:mt-0 mt-8"} src={boehm}
                        alt={"Kirsten Susanne Böhm"} />
                    <div className={"flex-grow grid content-center md:mt-0 mt-6 md:ml-8"}>
                        <h2 className={"text-white text-3xl font-sans font-thin antialised"}>Kirsten Susanne Böhm</h2>
                        <p className={"text-gray-500 text-md font-serif mb-4"}>Sopran</p>
                        <p className={"text-white text-md font-serif"}>Kirsten Susanne Böhm ist seit 2005 freischaffende
                            vocal artist in Nordfriesland und Schleswig-Holstein, bekannt für das von ihr gegründete
                            Ensemble SCHRAAG baroCKrossover. 2008 gründete sie das Vokalensemble Kleiner Chor Husum, als
                            dessen künstlerische Leiterin sie seither selten gesungene a-cappella-Musik erarbeitet. Die
                            letzten Produktionen seit 2020 konzentrieren ihren Ansatz, zur neuen Vermittlung klassischer
                            Musik spartenübergreifende Konzepte zu entwickeln. Seit November 2023 ist sie bei den
                            Chorknaben Uetersen und dem Kieler Knabenchor Fachkraft für Stimmbildung sowie beim Kieler
                            Knabenchor Leiterin des Nachwuchschores.</p>
                    </div>
                </div>
                <div className={"flex md:flex-row flex-col md:mt-8 mt-0"}>
                    <div className={"flex-grow grid content-center md:mt-0 mt-6 md:mr-8 md:order-first order-last"}>
                        <h2 className={"text-white text-3xl font-sans font-thin antialised"}>Christian Horn</h2>
                        <p className={"text-gray-500 text-md font-serif mb-4"}>Bandoneon</p>
                        <p className={"text-white text-md font-serif"}>Nach dem Studium von Kontrabass in Dresden und
                            Berlin wurde Christian Horn 1982 Solobassist an der Komischen Oper Berlin und
                            Gründungsmitglied der "Akademie für Alte Musik Berlin". Als Spezialist im Bereich der
                            historischen Aufführungspraxis auf der Großkontrabassgambe und dem Kontrabass in "Wiener
                            Stimmung", führten ihn Tourneen durch ganz Europa und nach Ostasien, an welche sich
                            zahlreiche Rundfunk- und CD-Produktionen anschlossen. 2008 erlernte er autodidaktisch
                            Bandoneón in "Rheinisch-Argentinischer Stimmung" und gastiert mittlerweile als Solist, in
                            Duo und Ensemble-Besetzungen in einer Kombination von Bandoneónist und Kontrabassist.</p>
                    </div>
                    <img className={"object-contain max-h-[25rem] flex-none md:mt-0 mt-12"} src={horn}
                        alt={"Christian Horn"} />
                </div>
                <div className={"flex md:flex-row flex-col md:mt-8 mt-0"}>
                    <img className={"object-contain max-h-[25rem] flex-none md:mt-0 mt-12"} src={duddeck}
                        alt={"Beat Duddeck"} />
                    <div className={"flex-grow grid content-center md:mt-0 mt-6 md:ml-8"}>
                        <h2 className={"text-white text-3xl font-sans font-thin antialised"}>Beat Duddeck</h2>
                        <p className={"text-gray-500 text-md font-serif mb-4"}>Altus</p>
                        <p className={"text-white text-md font-serif"}>Beat Duddeck trat schon als Knabe am
                            Staatstheater Braunschweig als Solist auf. Neben umfangreichen chorsängerischen
                            Aktivitäten studierte er Mathematik und Physik in Stuttgart und Hannover sowie Gesang an
                            der Musikhochschule Köln. Als Solist ist er heute ein gefragter Altist im Oratorienfach.
                            Beat Duddeck singt regelmäßig mit verschiedenen in- und ausländischen Ensembles für Musik
                            der Renaissance. Beat Duddeck arbeitet regelmäßig mit führenden Interpreten Alter Musik
                            zusammen. Im Jahr 2021 erschien die erste Solo-CD mit Kantaten des norddeutschen Frühbarock
                            in Zusammenarbeit mit dem Ensemble Schirokko Hamburg.
                        </p>
                    </div>
                </div>
                <div className={"flex md:flex-row flex-col md:mt-8 mt-0"}>
                    <div className={"flex-grow grid content-center md:mt-0 mt-6 md:mr-8 md:order-first order-last"}>
                        <h2 className={"text-white text-3xl font-sans font-thin antialised"}>Laura Frey</h2>
                        <p className={"text-gray-500 text-md font-serif mb-4"}>Gambe</p>
                        <p className={"text-white text-md font-serif"}>Laura Frey studierte Alte Musik mit Hauptfach
                            Viola da gamba an der Staatlichen Hochschule für Musik Freiburg. Sie konzertiert
                            europaweit, sowie bei zahlreichen internationalen Festivals und produziert für CD, Rundfunk
                            und Fernsehen. Außerdem ist sie festes Mitglied von La Visione, Ensemble 714 und ViolsVoice.
                            Laura Frey ist auch Dozentin im Rahmen von Kursen für Gambe, Consort und Alte Musik. Sie
                            hat einen Lehrauftrag für Historische Aufführungspraxis, Lehrpraxis Alte Musik und Viola
                            da gamba an der Musikakademie der Stadt Kassel „Louis Spohr“ inne.</p>
                    </div>
                    <img className={"object-contain max-h-[25rem] flex-none md:mt-0 mt-12"} src={frey}
                        alt={"Laura Frey"} />
                </div>
                <div className={"flex md:flex-row flex-col md:mt-8 mt-0"}>
                    <img className={"object-contain max-h-[25rem] flex-none md:mt-0 mt-12"} src={krueger}
                        alt={"Carsten Krüger"} />
                    <div className={"flex-grow grid content-center md:mt-0 mt-6 md:ml-8"}>
                        <h2 className={"text-white text-3xl font-sans font-thin antialised"}>Carsten Krüger</h2>
                        <p className={"text-gray-500 text-md font-serif mb-4"}>Bass</p>
                        <p className={"text-white text-md font-serif"}>Der Bass-Bariton Carsten Krüger absolvierte ein Studium der Schulmusik, Germanistik und des Darstellenden Spiels in Hannover, bevor er an der Hochschule für Künste Bremen Gesang mit Schwerpunkt „Alte Musik“ studierte. Neben seiner regen solistischen Konzerttätigkeit im Bereich der Oratorien und Kantaten gilt seine besondere Vorliebe dem Ensemblegesang des 16. und 17. Jahrhunderts. Er ist Mitglied bei Cantus Thuringia, im Balthasar-Neumann-Chor und wirkt bei zahlreichen Ensembles der Alten Musik mit. Zudem ist er freier Mitarbeiter im NDR-Rundfunkchor Hamburg und im WDR-Chor Köln. Im Juni 2008 gab er sein Bühnendebut und war seitdem mehrere Jahre im Liebhabertheater Schloss Kochberg zu erleben.</p>
                    </div>
                </div>
                <div className={"flex md:flex-row flex-col md:mt-8 mt-0"}>
                    <div className={"flex-grow grid content-center md:mt-0 mt-6 md:mr-8 md:order-first order-last"}>
                        <h2 className={"text-white text-3xl font-sans font-thin antialised"}>Andreas Düker</h2>
                        <p className={"text-gray-500 text-md font-serif mb-4"}>Barockgitarre, Chitarrone, E-Bass</p>
                        <p className={"text-white text-md font-serif"}>Andreas Düker studierte Klassische Gitarre in
                            Kassel und war dort an mehreren Uraufführungen und Rundfunkaufnahmen mit Moderner Musik
                            beteiligt. Seine Künstlerische Reifeprüfung im Fach Laute legte er 1996 an der Hochschule
                            für Künste in Bremen ab. Neben der Renaissancelaute beherrscht er auch andere Instrumente
                            der Renaissance und des Barock. Außerdem interpretiert er Gitarrenmusik der Klassik und
                            Frühromantik auf einem Instrument der Zeit. Seit 2015 organisiert Andreas Düker die
                            Konzertreihe SAITENWECHSEL-Musik mit ausschliesslich eigenen Konzertprogrammen. Außerdem
                            widmet er sich in mehreren Crossover-Projekten der Verbindung von Alter Musik, Jazz und
                            Folk.</p>
                    </div>
                    <img className={"object-contain max-h-[25rem] flex-none md:mt-0 mt-12"} src={dueker}
                        alt={"Andreas Düker"} />
                </div>
            </div>
            <div className={"flex flex-col justify-center gap-4 pt-16"}>
                <img className={"object-contain max-h-[40rem]"} src={flyer} alt={"EUFORIA"} />
            </div>
        </div>
    )
}

export default Euforia